import React, { useEffect, useState, useRef} from "react";
import { Container, Grid, Icon, List, Button } from "semantic-ui-react";
import scrollTo from 'gatsby-plugin-smoothscroll';
import hideWidget from "./hideWidget";
import "./index.css";
import CTA from "../../components/cta/cta";
import JobListing from "../../components/job-listing/job-listing";
import SiteFooter from "../../components/site-footer/site-footer"
import CookieConsentModal from '../../components/cookie-consent-modal/cookie-consent-modal';
import SEO from "../../components/seo/seo";
import CookieUtils from '../../utils/cookie-utils';
import { navigate } from "gatsby";
import logo from "../../images/rocksteady-logo.png";
import samBioPicOne from "../../images/sam-with-kid-in-red-dress.png";
import jasonBioPicOne from "../../images/jason-passing-guitar-to-kid.png";
import annaBioPicOne from "../../images/anna-helping-kid-with-red-guitar.png";
import mark from "../../images/mark-portrait.png";
import wellbeingAwardWinners from "../../images/wellbeing-award-winners.png";
import trinityCollege from "../../images/trinity-college.png";
import mia from "../../images/music-industries-association.png";
import ism from "../../images/ism-corporate-member.png";
import QuoteSegment from "../../components/quote-segment/quote-segment";

const BAND_LEADER_DATA = Object.freeze([
  {
    name: 'sam',
    colorClass: 'electric-blue',
    quoteOne: "I was an experienced musician but I'd never taught before",
    quoteTwo: "Working full-time and enjoying music all day is the best job I've ever had",
    bio: [
      'Sam has been with Rocksteady for five years. Before joining Rocksteady Sam was working as a delivery driver during the day and playing gigs at the weekend.',
      '"At work I can be an awesome rock & roll role model. I love inspiring children and this is the best job I\'ve ever had. I\'ve brought my passion for music into my day job and still have time for my own music outside work."',
      [
        '"Rocksteady employs a huge community of musicians and people who just love music. There\'s always a great vibe when we meet up at team and whole company events during the year. Working with like-minded people is brilliant, everyone is so supportive.',
        'We all understand the massive positive difference playing music can offer children and have one mission, to make it as fun and accessible to as many children as we can.',
        'Rocksteady offers so many opportunities to develop, not only as a teacher and as a musician but to move into other roles within the organisation too."'
      ],
      'Check out our roles today to teach the next generation of musicians in a progressive and inclusive way.'
    ],
    uncaptionedBioPic: samBioPicOne,
    bioPicCaption: '"I\'ve brought my passion for music into my day job and still have time for my own music outside work”. - Sam'
  },
  {
    name: 'jason',
    colorClass: 'electric-green',
    quoteOne: "I'd had some experience of teaching but never in a band setting",
    quoteTwo: "I saw the awesome benefits children experienced learning music in a band and realised I had to work for Rocksteady",
    bio: [
      'Jason has been with Rocksteady for seven years. Before joining Rocksteady he was working as an independent music teacher.',
      '"It\'s a musician\'s dream to do the Rocksteady job. Every day you are making a difference to hundreds of children\'s lives. Teaching children in a band setting develops confidence, teamwork and they learn faster because they want their band to be the best it can be."',
      [
        '"It is full-on when you are teaching in schools but you have a large team supporting you which is brilliant. Rocksteady has a unique approach to teaching children which is inclusive and accessible. It is about playing the songs they love and being rock stars at their end of term concerts. I wish Rocksteady had been around when I was at primary school',
        'We use some amazing, easy to use, digital apps which makes it super simple to track children\'s progress. I can see everything I need to help them move forward in an instant, and I have more free time without preparing progress reports or planning.',
        'The Rocksteady curriculum incorporates 300 music skills which the children learn at their own pace, with no pressure. It has been awesome helping the Yrs 5 & 6 children work towards our exam free \'Music Performance in Bands\' qualification which we developed in collaboration with Trinity College London."'
      ],
      'Are you a qualified music teacher or a talented musician who teaches music independently? Check out our roles today to teach the next generation of musicians in a progressive and inclusive way.'
    ],
    uncaptionedBioPic: jasonBioPicOne,
    bioPicCaption: '"I enjoyed being a music teacher, but when I saw the huge benefits children experienced learning music in a band, I knew I had to work for Rocksteady” - Jason'
  },
  {
    name: 'anna',
    colorClass: 'electric-yellow',
    quoteOne: "I've performed as a vocalist but had limited instrument skills and wanted to learn more",
    quoteTwo: "I was a singer, I couldn't believe there was a job like this one. I felt a bit weaker on some instruments but I'm fine thanks to the training",
    bio: [
      'Anna has been with Rocksteady for five years. She joined her first professional band \'The Get Ups\' straight after graduating from university. Anna worked as a singer in hotels and on cruise ships, performing six nights a week.',
      '"While I really love performing music live and I\'d had a great time, I\'d reached a point in my life when I wanted stability and needed a full-time salary. I thought I\'d have to settle for a job outside music, so when I saw the opportunity to work for a music school and teach children to play in bands I knew it was perfect for me."',
      [
        '“I\'ve helped lots of children with confidence issues, I love being part of that. Watching a child get a buzz from performing when they didn\'t even think they\'d be able to get up on the stage. Empowering children to be their best selves through playing in a band is so rewarding, it gives you such a sense of purpose.',
        'As well as learning how to teach children music, Rocksteady has also helped me develop my own music and instrument skills. The personalised training and support offered here is second to none, we are so lucky to have a dedicated academy for staff development.',
        'There\'s plenty of time in the evenings, weekends and school holidays to gig and write my own songs."'
      ],
      'Check out our roles today to teach the next generation of musicians in a progressive and inclusive way.'
    ],
    uncaptionedBioPic: annaBioPicOne,
    bioPicCaption: '“I was a singer in a band, I couldn\'t believe there was a job out there like this one. I felt a bit weaker on some instruments but I\'m fine now thanks to all the training” - Anna'
  }
]);

const BAND_LEADER_BENEFITS = Object.freeze([
  'Full time salary',
  '12 weeks paid holiday',
  'Fuel Allowance',
  'Full training and ongoing support',
  '24/7 online GP service',
  'All staff jams and inspiring away-days',
  'Opportunity to make a positive impact through our Foundation',
  'Retailer discounts'
]);

const BAND_LEADER_BENEFITS_ALT = Object.freeze([
  'A full-time, salaried career in music',
  '12 weeks paid holiday',
  'Fuel allowance',
  'Comprehensive onboarding training',
  'Leading education technology with no need for lesson prep or marking',
  'Your evenings and weekends are free leaving time for you to continue your own music making and bring that real life experience back into lessons',
  'Ongoing professional development throughout your career'
]);

// This page is now the main page for the careers section of the website.
// Canonical tags in other pages of the careers section of the site should point here.

// There is some duplicate logic in this section, compared with the main site.
// In the future if more pages are required for specific campaigns we should consider
// arranging it so such layouts/logic are not duplicated.

const Layout = ({ children }) => {
  
  const anchorLinks = [
    ['A Career With Purpose', '#career-with-purpose'],
    ['Benefits', '#benefits'],
    ['Life at Rocksteady', '#life-at-rocksteady']
  ]

  const scrollToTop = () => scrollTo('#page-container');

  return (
    <Container id="page-container">
      <SEO
        title="Music Teacher Careers"
        keywords={[`rocksteady`]}
        description="Looking for your dream music job? Explore music teaching careers with Rocksteady and inspire the next generation through rock and pop band lessons."
      />
      <div className="sticky-header">
        <button onClick={scrollToTop}>
          <img
            src={logo}
            className="header-logo"
            alt="Rocksteady Music School"
          />
        </button>
        <div className="anchor-links">
          {anchorLinks.map(([text, href]) => (
            <button key={href} onClick={() => scrollTo(href)}>
              {text}
            </button>
          ))}
        </div>
      </div>
      {children}
      <CookieConsentModal />
      <SiteFooter />
      <div id="sticky-jobs-button">
        <CTA
          ctaText="View All Roles"
          onClick={() => navigate("/careers/all-roles/")}
          siteSection="careers"
          className={"CTA-mobile-margin"}
        />
        <CTA
          ctaText="View Teaching Jobs"
          onClick={() => scrollTo('#job-openings')}
          siteSection="careers"
          className={"CTA-mobile-margin"}
        />
      </div>
    </Container>
  );
};

const HeroSection = ({ onClick }) => (
  <div className="hero background-sky-blue space-between">
  
    <h1>This could be your dream music job.</h1>
          <CTA
            ctaText="View All Roles"
            onClick={() => navigate("/careers/all-roles/")}
            siteSection="primary"
            style={{marginRight: 6, marginTop: 12}}
          />
          <CTA
            ctaText="View Teaching Jobs"
            onClick={onClick}
            siteSection="primary"
            style={{marginRight: 6, marginTop: 12}}
          />
  </div>
          
);

const BandLeaderLearnMoreCard = ({ name, quote, onClick }) => (
  <div className={`learn-more-card background-${name.toLowerCase()}-tall-gradient`}>
    <h2>{name}</h2>
    <p>"{quote}"</p>
    <button onClick={onClick} className="learn-more-button">
      Learn More
    </button>
  </div>
);

const MeetBandLeaderCard = ({ name, quote, selected, onClick }) => (
  <button onClick={onClick} className={`background-${name}-short${selected ? '-gradient' : ''}`}>
    <div className="meet-bandleader-card">
      <h2>Meet {name}</h2>
      <p>"{quote}"</p>
    </div>
  </button>
);

const Page = () => {
  const DEPARTMENT_NAME = 'Band Leaders';
  const [urlParams, setUrlParams] = useState('');

  useEffect(() => {
    setUrlParams(CookieUtils.analyticsConsentGiven() ? '' : '?dnt=1');
  }, []);

  // Client-side Runtime Data Fetching
  const [departments, setdepartments] = useState([]);
  useEffect(() => {
    // get data from Green House api
    fetch("https://boards-api.greenhouse.io/v1/boards/rocksteadymusicschool/departments")
      .then(response => response.json()) // parse JSON from request
      .then((resultData) => {
        // Only set the band leader department for this page
        setdepartments(resultData.departments.filter(({ name }) => name === DEPARTMENT_NAME));
      });
  }, []);

  // Pass along Greenhouse URL params for marketing campaigns
  // See tracking info on https://app2.greenhouse.io/jobboard/integration/documentation/api_board_hosted_apps
  const [source, setSource] = useState("rocksteady_www_no_referrer")
  useEffect(() => {
    // Get GH Source code from URL params
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ghSrc = urlParams.get('gh_src');
    if(ghSrc) {
      setSource(ghSrc)
    }
  }, [])

  const [selectedBandLeader, setSelectedBandLeader] = useState(BAND_LEADER_DATA[0].name);
  const setSelectedFor = (name) => () => setSelectedBandLeader(name);
  const setSelectedAndNavigateToFor = (name) => () => {
    setSelectedBandLeader(name);
    scrollTo('#life-at-rocksteady');
  };

  const renderBenefitsList = (benefitList) => benefitList.map((benefit) => (
    <List.Item key={benefit}>
      <Icon name='check' className="blue-suede-shoes" />
      <List.Content>{benefit}</List.Content>
    </List.Item>
  ));

  const getBandLeaderData = (nameQuery) => BAND_LEADER_DATA.find(({ name }) => name === nameQuery);
  const getMeetBandLeaderColor = (nameQuery) => getBandLeaderData(nameQuery).colorClass;
  const getMeetBandLeaderBioSection = (nameQuery, index) => getBandLeaderData(nameQuery).bio[index];
  const getMeetBandLeaderBioPic = (nameQuery) => getBandLeaderData(nameQuery).uncaptionedBioPic;
  const getMeetBandLeaderBioPicCaption = (nameQuery) => getBandLeaderData(nameQuery).bioPicCaption;
  const scrollToJobOpenings = () => scrollTo('#job-openings');

  
  const [rightScrollCount, setRightScrollCount] = useState(0)
  
  const scroll = (scrollOffset) => {
    if (scrollOffset > 0) {
    setRightScrollCount(rightScrollCount + 1);
    } else {
      setRightScrollCount(rightScrollCount - 1);
    };
    carouselRef.current.scrollLeft += scrollOffset;
  };

  const carouselArrow = (direction) => {
    if (direction === "right" && rightScrollCount < 2) {
      return (
        <div className="scroll-btn scroll-btn-right">
              <Button icon active circular onClick={() => scroll(310)} className="grey-btn">
                <Icon disabled name="angle right" size="large"/>
              </Button>
            </div>
      )
    } else if (direction === "left" && rightScrollCount > 0) {
      return (
        <div className="scroll-btn scroll-btn-left">
              <Button icon active circular onClick={() => scroll(-310)}>
                <Icon disabled name="angle left" size="large"/>
              </Button>
            </div>
      )
    } else {
      return null;
    }
  }

  const carouselRef = useRef(null);
  return (
    <Layout>
      {hideWidget()}
      <div>
        <HeroSection onClick={scrollToJobOpenings} />
        <div className="section mt-25">
          <h2>There's no one 'traditional' route to becoming a Rocksteady Band Leader</h2>
          <p>Our music teachers are talented musicians and vocalists who often come from a variety of different backgrounds such as retail, hospitality, finance and education. At Rocksteady everyone benefits from extensive training as well as on-going support and personalised development opportunities. What connects everyone is a passion for music, a desire to have a job with purpose and a willingness to continue to learn and develop. This full-time salaried role leaves everyone with time for gigging and music-making during evenings, weekends and a generous 12 weeks paid holiday.</p>
          <p><strong>Click below to discover more about the experiences of Rocksteady staff.</strong></p>
        </div>

        <div className="no-section-mobile mobile-no-padding-top position-relative">
          <div className="carousel" ref={carouselRef}>
            {BAND_LEADER_DATA.map(({ name: blName, quoteOne }) => (
              <BandLeaderLearnMoreCard
                key={`learn-more-${blName}`}
                name={blName}
                quote={quoteOne}
                onClick={setSelectedAndNavigateToFor(blName)}
              />
            ))}
            {carouselArrow("left")}
            {carouselArrow("right")}
          </div>
        </div>

        <br />

        {/* Video has been taken down temporarily - reinstate when replacement is ready */}
        {/*<<div className="section">
          <div id="career-with-purpose" className="offsetted-anchor"/>
          <h2>Want a music career with purpose?</h2>
          <div id="careers-video-container">
            <iframe
              className="careers-video"
              src={`https://player.vimeo.com/video/[id goes here]${urlParams}`}
              width="640"
              height="480"
              allow="autoplay; fullscreen"
              frameBorder="0"
              allowFullScreen
              title="Rocksteady Music School Careers"
            ></iframe>
          </div>
        </div>*/}

        <div className="section background-yellow mt-25">
          <div id="benefits" className="offsetted-anchor"/>
          <h2>Benefits of Being a Music Teacher at Rocksteady</h2>
          <p><strong>Join an award-winning, highly successful, music service and work with like-minded people who are all passionate about music.</strong></p>
          <Grid stackable>
            <Grid.Row columns={2} only="computer tablet">
              <Grid.Column>
                <List relaxed>
                  {renderBenefitsList(
                    BAND_LEADER_BENEFITS.slice(
                      0,
                      -(parseInt(BAND_LEADER_BENEFITS.length / 2, 10))
                    )
                  )}
                </List>
              </Grid.Column>
              <Grid.Column>
                <List relaxed>
                  {renderBenefitsList(
                    BAND_LEADER_BENEFITS.slice(
                      -(parseInt(BAND_LEADER_BENEFITS.length / 2, 10))
                    )
                  )}
                </List>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2} only="mobile">
              <Grid.Column>
                <List relaxed>{renderBenefitsList(BAND_LEADER_BENEFITS)}</List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        <div className="section mt-25">
          <div id="job-openings" className="offsetted-anchor"/>
          <div className="background-grey section">
            <h2>Current Job Openings</h2>
            <p>Rocksteady continues to grow across the UK so if you think this could be your dream job and your area is not listed below, please click the Register Interest button and we'll let you know when we launch in your area.</p>
            <Grid relaxed columns={1}>
              <Grid.Column>
                <JobListing departments={departments} source={source} showDepartmentTitles={false} />
              </Grid.Column>
            </Grid>
            <Grid columns={1}>
              <Grid.Column>
                <p><strong>Not a job in your area?</strong></p>
                <Grid stackable columns={2}>
                  <Grid.Column width={16}>
                    <p>Sign up for job alerts and we'll contact you when a vacancy comes up. You can also submit a talent pool application and you'll make your way through the recruitment process ready for when a role becomes available.</p>
                  </Grid.Column>
                  <Grid.Column className="register-interest-cta" mobile={16}>
                    <CTA
                        ctaText="Sign up for Job Alerts"
                        ctaLink="https://eepurl.com/iEq_9c"
                        siteSection="primary"
                        style={{marginRight: 6, marginTop: 12}}
                    />
                    <CTA
                      ctaText="Join Our Talent Pool"
                      ctaLink="https://boards.greenhouse.io/rocksteadymusicschool/jobs/6548200002?gh_src=rocksteady_www_no_referrer"
                      siteSection="primary"
                      style={{marginRight: 6, marginTop: 12}}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </div>
        </div>

        <div className="section">
          <div id="life-at-rocksteady" className="offsetted-anchor"/>
          <h2>Life at Rocksteady</h2>
          <h3>What our Band Leaders Say</h3>
          <div id="meet-bandleader-cards">
            {BAND_LEADER_DATA.map(({ name: blName, quoteTwo }) => (
              <MeetBandLeaderCard
                key={`meet-${blName}`}
                onClick={setSelectedFor(blName)}
                name={blName}
                quote={quoteTwo}
                selected={selectedBandLeader === blName}
              />
            ))}
          </div>

          <div className={`meet-band-leaders background-${getMeetBandLeaderColor(selectedBandLeader)}`}>
            <Grid relaxed stackable columns={2}>
              <Grid.Row>
                <Grid.Column tablet={16} computer={8}>
                  <h2>{`Meet Bandleader ${selectedBandLeader}`}</h2>
                  <p>{getMeetBandLeaderBioSection(selectedBandLeader, 0)}</p>
                  <h4>Why did you apply to work at Rocksteady?</h4>
                  <p>{getMeetBandLeaderBioSection(selectedBandLeader, 1)}</p>
                </Grid.Column>
                <Grid.Column tablet={16} computer={8}>
                  <div id="captioned-bio-pic" className={`bio-pic-background-${selectedBandLeader}`}>
                    <p>{getMeetBandLeaderBioPicCaption(selectedBandLeader)}</p>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column only="computer">
                  <img
                    style={{ width: '100%' }}
                    src={getMeetBandLeaderBioPic(selectedBandLeader)}
                    alt="band leader playing music"
                  />
                </Grid.Column>
                <Grid.Column tablet={16} computer={8}>
                  <h4>What is it like to work here?</h4>
                  {getMeetBandLeaderBioSection(selectedBandLeader, 2)
                    .map((para, i) => <p key={`meet-bl-para-${i + 1}`}>{para}</p>)}
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid stackable columns={2}>
              <p>{getMeetBandLeaderBioSection(selectedBandLeader, 3)}</p>
              <p>Some of the support and benefits you can expect when joining Rocksteady:</p>
              <Grid.Row stretched only="computer tablet">
                <Grid.Column>
                  <List relaxed>
                    {renderBenefitsList(
                      BAND_LEADER_BENEFITS_ALT.slice(
                        0,
                        -(parseInt(BAND_LEADER_BENEFITS_ALT.length / 2, 10))
                      )
                    )}
                  </List>
                </Grid.Column>
                <Grid.Column>
                  <List relaxed>
                    {renderBenefitsList(
                      BAND_LEADER_BENEFITS_ALT.slice(
                        -(parseInt(BAND_LEADER_BENEFITS_ALT.length / 2, 10))
                      )
                    )}
                  </List>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row only="mobile">
                <Grid.Column>
                  <List relaxed>{renderBenefitsList(BAND_LEADER_BENEFITS_ALT)}</List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div className="meet-band-leaders-cta">
              <CTA
                ctaText="View Available Jobs"
                onClick={scrollToJobOpenings}
                siteSection="careers"
              />
            </div>
          </div>
        </div>

        <div className="section">
          <Grid verticalAlign="middle" stretched>
            <Grid.Column only="computer" width={2} />
            <Grid.Column mobile={10} tablet={8} computer={6}>
              <img width="75%" src={mark} alt="Mark Robinson" />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <h2>Inspiring the next generation</h2>
              <p>Rocksteady was founded by Mark Robinson, a musician, music teacher and author. He saw first-hand how children progressed faster when they were playing popular rock and pop music in a band.</p>
              <p>Since then Rocksteady have grown to become the UK's leading rock and pop music teaching service for primary schools and one of the biggest employers of musicians in the UK. Teachers deliver accessible, progressive, band lessons to tens of thousands of pupils every week, helping to inspire the next generation of musicians.</p>
              <p>Our mission is to empower every child through music. In addition to teaching in schools, each year The Rocksteady Foundation organises hundreds of free music sessions for children and young people in charities and support groups across the UK. We enable some of the most vulnerable in society to enjoy the wellbeing, fun and educational benefits of playing music in a band.</p>
            </Grid.Column>
          </Grid>
        </div>

        <div className="section background-yellow mt-25">
          <QuoteSegment
            quote={[
              'Learning to play an instrument became less daunting to many children; it was about teamwork, having fun, expressing themselves in a safe environment and discovering a genuine love of learning music.'
            ]}
            quoter="Mark Robinson - Founder and Chairman, Rocksteady Music School"
          />
        </div>

        <div className="section mt-25">
          <Grid centered stackable relaxed columns={2}>
            <Grid.Column mobile={16} computer={6}>
              <div>
                <img width="100%" src={wellbeingAwardWinners} alt="Rocksteady staff celebrating award win" />
              </div>
              <p className="blue-text">Winner - 'Wellbeing Resource of the Year' - Education Today Awards 2022/2023</p>
            </Grid.Column>
            <Grid.Column mobile={16} computer={8}>
              <QuoteSegment
                quote={[
                  'We cannot begin to quantify the impact Rocksteady has on our whole school community.',
                  'These lessons aid not only music development but also social communication and interaction. The whole school community looks forward to their termly concerts when we come together and celebrate the achievements of so many children.'
                ]}
                quoter="Alison Fitch, Co-Head, Boxgrove Primary School, Guildford"
              />
            </Grid.Column>
          </Grid>
        </div>

        <div className="section background-sky-blue mt-25">
          <div className="partners-awards-badges">
            <img src={trinityCollege} alt="Trinity College logo" />
            <img src={mia} alt="MIA logo" />
            <img src={ism} alt="ISM logo" />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Page;
